exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bangladesh-js": () => import("./../../../src/pages/bangladesh.js" /* webpackChunkName: "component---src-pages-bangladesh-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-governance-js": () => import("./../../../src/pages/governance.js" /* webpackChunkName: "component---src-pages-governance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-india-js": () => import("./../../../src/pages/india.js" /* webpackChunkName: "component---src-pages-india-js" */),
  "component---src-pages-last-mile-access-js": () => import("./../../../src/pages/last-mile-access.js" /* webpackChunkName: "component---src-pages-last-mile-access-js" */),
  "component---src-pages-nepal-js": () => import("./../../../src/pages/nepal.js" /* webpackChunkName: "component---src-pages-nepal-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-systems-change-js": () => import("./../../../src/pages/systems-change.js" /* webpackChunkName: "component---src-pages-systems-change-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

